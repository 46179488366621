import React, {useState, FC } from "react";
import { AgGridReact } from 'ag-grid-react';
import { useGenericGridViewElements, GridViewFrameworkComponentsType, GridViewAgGridContainer, GridViewApprovalStatusBadgeCellRenderer } from '../../../../legacy-components/grid-view/GridView';
import { FormattedMessage, useIntl } from "react-intl";
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Box, IconButton, Link } from "@material-ui/core";
import ApplicationService from "../../../../libs/resources/identity/applications/ApplicationService";
import CheckCircleRounded from "@material-ui/icons/CheckCircleRounded";
import CancelIcon from '@material-ui/icons/Cancel';
import SettingsIcon from '@material-ui/icons/Settings';
import { useHistory } from 'react-router-dom';
import Unsubscriber from "pages/home/subscriptions/components/Unsubscriber/Unsubscriber";
import SectorIcon from "legacy-components/icons/SectorIcon";
import { Date } from "modus/components/list/table/TableUtils";
import { SubscriptionCreator } from "./SubscriptionCreator";
import { IdentityApplication } from "../services/ApplicationService";


export interface ISubscriptionsListProps{
    consumerKey: string;
    application: IdentityApplication
}

export interface ISubscription {
    productId: string;
    status?: string;
}
export const SubscriptionsList : React.FC<ISubscriptionsListProps> = ({application, consumerKey}) => 
{    
    const intl = useIntl();
    const noDataMessage = <FormattedMessage defaultMessage="No subscriptions available" />;

    const { collection, onGridReady, frameworkComponents, gridViewOverlayPropRef, getRowNodeId, createRowActionColumn, refreshStream } = useGenericGridViewElements({
        resolverFn: () => ApplicationService.getSubscriptions(consumerKey),
        noDataMessage
    });

    const StatusBadge = ({ data }: ICellRendererParams) => {
        if (data.status === "SUBSCRIBED")
            return <CheckCircleRounded style={{ color: "#006638", marginTop: "10px" }} />
        else if (data.status === "PENDING_REVIEW") 
            return <SectorIcon style={{marginTop : "10px"}}/>
        else if (data.status === "DENIED" || data.status === "REVOKED") 
            return <CancelIcon style={{marginTop : "10px"}}/>
        else
            return <CancelIcon style={{ color: ("#A9A9A9")}}/>
    };
    const history = useHistory();

    const ProductTitle = ({data}: ICellRendererParams) => {
        const onProductClick = () => {
            history.push(`/home/consumer/products/${data.productId}`)
        }
        return (
            <div style = {{color:"#00437B"}}>
            <Link  color="primary" onClick={onProductClick}>{data?.productName}</Link>
            </div>
        )
    }

    const Actions = ({ data }: ICellRendererParams) => {
        const onSubscriptionView = (data: any) => {
            history.push(`/home/subscriptions/${data.subscriptionId}`)
        }
       return (
        <Box>
        {data?.productId && (
            <Box>
            <IconButton onClick={() =>onSubscriptionView(data)}>
            <SettingsIcon /> 
        </IconButton>  
         <Unsubscriber
         subscriptionId={data?.subscriptionId}
         applicationName={data.applicationName}
         isIcon={true}
         isPrimaryColor={false}
         reloadData={refreshStream}

       />
       </Box>
        )}
        
       </Box>
       )
    };

    const [columnDefs] = useState<ColDef[]>(() => {
        const columns: ColDef[] = [
            {
            field: "productName",
            sortable: true,
            unSortIcon : true,
            filter: "agTextColumnFilter",
            filterParams: {
                suppressAndOrCondition: true,
                defaultOption: 'contains',
                filterOptions: ['contains']
            },
            comparator: (valueA, valueB) => { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
            headerName: intl.formatMessage({
                defaultMessage: "ProductName"
            }),
            flex: 1,
            cellRendererFramework : ProductTitle

        }, {
            field: "subscribedDate",
            sortable: true,
            unSortIcon : true,
            headerName: intl.formatMessage({
                defaultMessage: "Subscribed Date"
            }),
            flex: 1,
            cellRenderer: "Date"
        },
        {
            field: "status",
            sortable: true,
            unSortIcon : true,
            headerName: intl.formatMessage({
                defaultMessage: "Status"
            }),
            flex: 1,
            resizable: true,

            cellRendererFramework: StatusBadge,
        },
        {
            headerName: intl.formatMessage({
                defaultMessage: "Actions"
            }),
            flex: 1,
            resizable: true,

            cellRendererFramework: Actions,
        },
    
        ];

        return columns;
    });

   
    return (
        <>
        <Box my={2} display={"flex"} justifyContent={"flex-end"}>
            <SubscriptionCreator subscribedProducts={collection?.value} application={application} refreshSubscriptionList={refreshStream}/>
        </Box>
        <GridViewAgGridContainer>
            <AgGridReact
                columnDefs={columnDefs}
                frameworkComponents={{...frameworkComponents, Date, Actions}}
                loadingOverlayComponent={GridViewFrameworkComponentsType.LoadingOverlay}
                noRowsOverlayComponent={GridViewFrameworkComponentsType.NoRowsOverlay}
                noRowsOverlayComponentParams={{ gridViewOverlayPropRef }}
                suppressRowClickSelection={true}
                suppressCellSelection={true}
                suppressRowHoverHighlight={true}
                getRowNodeId={getRowNodeId}
                onGridReady={onGridReady}
            />
        </GridViewAgGridContainer>
        </>
    )
}