import { Box } from "@material-ui/core";
import { withViewController } from "modus/utilities/ViewController";
import React, { ReactElement } from "react";

export const ErrorMessage = ({message}: {message: string|JSX.Element}) => {
    return (
        <Box mb={1} p={3} mx={0} mt={3}
            width="100%" minHeight={"200px"} 
                border="1px solid #000" display={"flex"} 
                textAlign={"center"}
                justifyContent={"center"}
                fontSize={20}
                fontWeight={400}
                alignItems={"center"}>
            {message}
        </Box>
    );
}

const useAPIErrorMessage = (error: any) => {
  return {message: resolveAPIErrorMessage(error)};
}

export const APIErrorMessage = withViewController(ErrorMessage , useAPIErrorMessage);
  

interface APIErrorType1 {
    response: {
      data: {
        message?: string;
        code?: string;
      };
    };
  }
  interface APIErrorType2 {
    data: {
      message?: string;
      code?: string;
    };
  }
  interface APIErrorType3 {
    message?: string;
    code?: string;
  }
  export type APIErrorType =
    | APIErrorType1
    | APIErrorType2
    | APIErrorType3
    | unknown
    | any;
  
export const resolveAPIErrorMessage = (
    error: APIErrorType,
    customMessage: ReactElement | string = "Apologies! An error has occurred, Please try loading this page after some time."
  ) => {
    if(!error) {
        return customMessage;
    }
    
    if (error?.response?.data?.message) {
      return error?.response?.data?.message;
    }
    if (error?.message) {
      if (error?.message !== 'Network Error') {
        return error?.message;
      }
    }
    if (error?.data?.message) {
      return error?.data?.message;
    }
    if(error?.status || error?.error?.response?.status === 403 || error?.response?.status === 403) {
      return "You do not have permission to access this content.";
    }

    return customMessage;
  };


export const resolveAPIErrorCode = (
  error: APIErrorType
) => {
  if(!error) {
      return null;
  }
  if (error?.response?.data?.code) {
    return error?.response?.data?.code;
  }
  if (error?.response?.code) {
    return error?.response?.code;
  }
  if (error?.code) {
    if (error?.code !== 'Network Error') {
      return error?.code;
    }
  }
  if (error?.data?.code) {
    return error?.data?.code;
  }
  return null;
};

export const resolveAPIErrorWithCode = (
  error: APIErrorType,
  codeMapping: Record<string, string>,
) => {
  const code = resolveAPIErrorCode(error);
  return codeMapping[code] || resolveAPIErrorMessage(error);
}