import React, { ChangeEvent, useCallback, useEffect, useState } from "react";

import { Box, Chip, InputLabel, MenuItem, Link } from "@material-ui/core";
import type { IApiEndpoint, IDeployments, IDeploymentDetails } from "../../../../service/ProductService";
import { Column, Row } from "legacy-components/responsive-grid/ResponsiveGrid";
import Typography from "legacy-components/typography/Typography";
import Select from "legacy-components/form/container/Select";
import { TextInput } from "legacy-components/form/container/TextInput";
import CopyToClipboard from "legacy-components/form/action/CopyToClipboard";
import environmentHelper from "../../../../../helper/EnvironmentHelper";
import Table, { TableColumn } from "legacy-components/table/Table";

interface APIEndpointsProps {
  endpoints: Array<IApiEndpoint>;
}
export const APIEndpoints: React.FC<APIEndpointsProps> = ({ endpoints }) => {

  const [selectedApiName, setSelectedApiName] = useState<string>();
  const [selectedEnvironment, setSelectedEnvironment] = useState<string>();
  const [selectedEndpoint, setSelectedEndpoint] = useState<string>("");
  const [data, setData] = useState<Array<IDeploymentDetails>>([]);
  const [selectedScopePrefix, setSelectedScopePrefix] = useState<string>("");

  const [availableEnvironment, setAvailableEnvironment] = useState<Array<IDeployments>>([]);

  var deploymentValueMap = new Map([
    ["us-west-1", "US West"],
    ["us-west-2", "US West"],
    ["us-east-1", "US East"],
    ["us-east-2", "US East"],
    ["us", "US"],
    ["global", "Global"],
    ["au", "Australia"],
    ["ap", "Asia Pacific"]
  ]);

  const getEnviornmentsInProxy = React.useCallback(
    () =>
      endpoints
        .filter((endpoint) => endpoint.apiName === selectedApiName)
        .map((endpoint: IApiEndpoint) => endpoint.deployment)[0],
    [endpoints, selectedApiName]
  );

  useEffect(() => {
   endpoints && setSelectedApiName(
      endpoints[0]?.apiName
    )
  }, [endpoints]);

  const handleApiChange = (event: any) => {
    setSelectedApiName(event?.target.value)
  };

  useEffect(() => {
    setAvailableEnvironment(
      endpoints.filter((endpoint) =>
        endpoint.apiName === selectedApiName
      )[0]?.deployment
    )
  }, [selectedApiName]);

  useEffect(() => {
    if(!availableEnvironment || !setSelectedEnvironment) {
      return;
    }
    availableEnvironment && setSelectedEnvironment(availableEnvironment[0]?.environment)
  }, [availableEnvironment,setSelectedEnvironment])

  const handleEnvironmentChange = (event: any) => {
    setSelectedEnvironment(event?.target.value)
  };

  useEffect(() => {
  availableEnvironment && setData(
    availableEnvironment.filter((env) => 
         env.environment === selectedEnvironment
    )[0]?.deploymentInfoList
  )}, [selectedEnvironment, selectedApiName, availableEnvironment, setData]);
  
  const Url = (props: { data: any }) => {
    return (
      <span className = {props?.data?.endpoint} key={props?.data?.endpoint}>
        <Link className = {props?.data?.endpoint} key={props?.data?.endpoint} href={props?.data?.endpoint} target="_blank">
          {props?.data?.endpoint}
        </Link>
      </span>
    );
  };

  const DeprecationBadge = (props: { data: any }) => {
    return (
        props?.data?.isDeprecated ? <Chip label="deprecated" size="small"/> : <></>
    );
  };

  const Region = (props: { data: any }) => {
    return deploymentValueMap.get(props?.data?.optionValue);
  };

  const Actions = (props: { data: any }) => {
    return (
      <CopyToClipboard size="small" edge="end" text={props?.data?.endpoint} key={props?.data?.endpoint} className={`copy-to-clipboard ${props?.data?.endpoint}`}/>
    );
  };

  return (
    <Column margin="y1">
      <Row margin="b1">
        <Typography type="h5">API Endpoints</Typography>
      </Row>
      {!endpoints ||
        (endpoints.length === 0 && (
          <Typography type="body">
            API Endpoints are not available for this API Product. Please contact support (Refer to the below section for contact details)
          </Typography>
        ))}

      {endpoints && endpoints.length > 0 && selectedApiName && selectedEnvironment && (
        <>
          <Typography type="body">
            Please select the appropriate API Endpoint needed for your
            Environment.
          </Typography>
          <Row xl={12} margin="t2">
            <Column xl={5} margin="r1">
              <InputLabel variant="standard">Proxy Name</InputLabel>
              <Select id="apiName" label="Proxy Name" onChange={handleApiChange} value={selectedApiName}>
              {endpoints.sort((a,b) => (a.apiName?.toLowerCase() >= b.apiName?.toLowerCase())?1:-1).map((endpoint: IApiEndpoint, index: number) => (
                  <MenuItem value={endpoint.apiName} key={index}>
                    {endpoint.apiName}
                  </MenuItem>
                ))}
              </Select>
            </Column>
            <Column xl={5} margin="l1">
              <InputLabel variant="standard">Environment</InputLabel>
              <Select id="environment" label="Environment" disabled={!selectedApiName} onChange={handleEnvironmentChange} value={selectedEnvironment}>
                {/* {selectedApiName &&
                  getEnviornmentsInProxy().map((deployment) => (
                    <MenuItem
                      value={deployment.environment}
                      key={deployment.environment}
                    >
                      {deployment.environment}
                    </MenuItem>
                  ))} */}
                {availableEnvironment && availableEnvironment.map((deployment: IDeployments, index: number) => (
                  <MenuItem value={deployment.environment} key={index}>
                    {(deployment.environment === "eu-west" ? "prod-eu" : deployment.environment)}
                  </MenuItem>
                ))}
              </Select>
            </Column>
          </Row>
          <Row margin="t2"/>
          <Table
          rowData={data?.map((response, index) => ({
             ...response,
             id: index
          }))}
          getRowNodeId={data => data.id}
          noRowsText="No Deployment for this API Proxy"
          height="300px"
          data-testid="proxy-deployment-table"
          frameworkComponents={{ Url, DeprecationBadge, Region, Actions }}
          >
          <TableColumn
          field="endpoint"
          flex={2.5}
          headerName="Endpoint"
          data-testid="Endpoint"
          cellRenderer={"Url"}
        />
        <TableColumn
          field="optionValue"
          flex={1}
          sortable
          headerName="Region" 
          cellRenderer={"Region"}
        />
          <TableColumn field="actions" cellRenderer="Actions" />
          </Table>
        </>
      )}
    </Column>
  );
};
