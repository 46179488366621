import React, { useState, useEffect } from 'react';
import { Box, Button, Drawer, Select, MenuItem, TextField, Typography, IconButton, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TrimbleColors } from 'legacy-components/TrimbleColors';
import { resolveTextToFilter } from './filter';
import EventService from '../service/EventService';
import { useValidateFilterCondition } from './EventFilterValidation';
import { useIdentityEnvironmentFilter } from 'pages/home/subscriptions/components/identity-environment-filter/IdentityEnvironmentFilter';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
    customTooltip: {
      fontSize: '16px',
      lineHeight: 1.7,
    },
  });

const StyledSelect = withStyles({
    root: {
      fontFamily: 'sans-serif',
      fontSize: '18px',
      fontWeight: 300,
    },
    icon: {
      color: TrimbleColors.Blue,
    },
  })(Select);
  
  const StyledMenuItem = withStyles({
    root: {
      fontFamily: 'sans-serif',
      fontSize: '18px',
      fontWeight: 300,
    },
  })(MenuItem);


interface EventDrawerProps {
    open: boolean;
    onClose: () => void;
    serviceList?: { [key: string]: string[] };
    subscriptionId: string;
    applicationId: string| undefined;
    onChange: () => void;
}

const EventDrawer: React.FC<EventDrawerProps> = ({ open, onClose, serviceList, subscriptionId, applicationId, onChange }) => {
    const classes = useStyles();
    const [selectedServiceInDrawer, setSelectedServiceInDrawer] = useState<string>('');
    const [selectedDeploymentInDrawer, setSelectedDeploymentInDrawer] = useState<string>('');
    const [description, setDescription] = useState<string>('');    
    const [filter, setFilter] = useState<string>('');
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const { validateFilterCondition } = useValidateFilterCondition();
    const [isFilterValid, setIsFilterValid] = useState<boolean>(true);

     const {eventConfiglist } = useIdentityEnvironmentFilter();
    
     const [serviceDeploymentList, setServiceDeploymentList] = useState<string[]>(eventConfiglist);

    useEffect(() => {
        setIsFormValid(
            selectedServiceInDrawer !== '' &&
            selectedDeploymentInDrawer !== '' &&
            description.trim() !== '' &&
            isFilterValid === true
        );
    }, [selectedServiceInDrawer, selectedDeploymentInDrawer, description, filter, isFilterValid]);

    const handleServiceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedService = event.target.value as string;
        setSelectedServiceInDrawer(selectedService);
        if (serviceList!==undefined)
            setServiceDeploymentList(serviceList[selectedService]);
        setSelectedDeploymentInDrawer('');
    };

    const handleDeploymentChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedDeploymentInDrawer(event.target.value as string);
    };

    const handleFormChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        if (name === 'description') {
            setDescription(value);
        }
        if (name === 'filter'){
            setFilter(value);
            if(value===''){
                setIsFilterValid(true);
            }else{
            setIsFilterValid(false);
            }
        }
    };

    const handleFormSubmit =  (event: React.FormEvent) => {
        event.preventDefault();
        if (isFormValid) {
            
            try {
                const requestPayload = {
                    description: description,
                    environment: selectedDeploymentInDrawer,
                    applicationId: applicationId,
                    service_version: selectedServiceInDrawer,
                    filter: filter !== '' ? resolveTextToFilter(filter) : null,

                };

                EventService.createEventSubscription(requestPayload, subscriptionId).toPromise()
                .then(()=>{
                    onChange();
                }); 
                onClose();
                
            } catch (error) {
                console.error("Error creating subscription:", error);
            }
        }
        setServiceDeploymentList([]);
        setSelectedServiceInDrawer('');
        setFilter('');
        setDescription('');
    };

    const handleVerifyClick = () => {
        const validationResult = validateFilterCondition(filter);
        setIsFilterValid(validationResult);
    };

    return (
        <Drawer anchor="right" open={open} onClose={onClose} style={{ width: 950 }}>
            <Box p={2} style={{ width: 933, paddingLeft: 54, maxHeight: '100vh', overflowY: 'auto', fontFamily: 'sans-serif' }}>
                <Typography style={{ marginBottom: 16, marginLeft:1, fontSize: '24px' }}>
                    New Event Configuration
                </Typography>
                <Typography variant="body1" style={{ fontSize: '16px', fontWeight: 300, marginBottom: 19, lineHeight: '20px', textAlign: 'left', letterSpacing: '0.15000000596046448px' }}>
                    Create a new event specific to your environment and version
                </Typography>
                <form onSubmit={handleFormSubmit}>
                    <Box mb={2}>
                        {/* <Typography style={{ marginBottom: 8, fontSize: '18px', fontWeight: 300 }}>
                            Versions */}
                        <Typography style={{ marginBottom: 8, fontSize: '19px', fontWeight: 400, marginTop: 40 }}>
                            Versions
                        </Typography>
                        <StyledSelect
                            fullWidth
                            variant="outlined"
                            value={selectedServiceInDrawer}
                            onChange={handleServiceChange}
                            displayEmpty
                            style={{width: 800}}
                            IconComponent={ExpandMoreIcon}
                        >
                            <StyledMenuItem value="" disabled>
                                <Typography style={{fontSize: '18px', fontWeight: 300, color: '#999' }}>Select a Version</Typography>
                            </StyledMenuItem>
                            {serviceList && Object.entries(serviceList).map(([version, deployments], index) => (
                                <StyledMenuItem key={index} value={version}>
                                    {version}
                                </StyledMenuItem>
                            ))}
                        </StyledSelect>
                        <Typography style={{ marginBottom: 8, marginTop: 50, fontSize: '19px', fontWeight: 400 }}>
                            Deployment
                        </Typography>
                        <StyledSelect
                            fullWidth
                            variant="outlined"
                            value={selectedDeploymentInDrawer}
                            onChange={handleDeploymentChange}
                            displayEmpty
                            style={{width: 800}}
                            IconComponent={ExpandMoreIcon}
                        >
                            <StyledMenuItem value="" disabled>
                                <Typography style={{ fontSize: '18px', fontWeight: 300, color: '#999' }}>Select a Deployment</Typography>
                            </StyledMenuItem>
                            {serviceDeploymentList.map((deployment, index) => (
                                <StyledMenuItem key={index} value={deployment}>
                                    {deployment}
                                </StyledMenuItem>
                            ))}
                        </StyledSelect>

                        <Typography style={{ marginBottom: 8, marginTop: 50, fontSize: '19px', fontWeight: 400 }}>
                            Description <span style={{ color: 'black', marginLeft: 3 }}>*</span>
                        </Typography>
                        <TextField
                            name="description"
                            onChange={handleFormChange}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            placeholder="Add short description here"
                            variant="outlined"
                            InputProps={{
                                style: {
                                    fontFamily: 'sans-serif',  
                                    fontSize: '18px',         
                                    fontWeight: 300,
                                },
                            }}
                            inputProps={{
                                style: {
                                    fontFamily: 'sans-serif',  
                                    fontSize: '18px',
                                    fontWeight: 300,           
                                },
                            }}
                            style={{
                                backgroundColor: '#fff',
                                borderRadius: 4,
                                width: 800,
                                marginTop: 4
                            }}
                        />
                    </Box>
                    <Typography style={{ marginBottom: 5, marginTop: 50, fontSize: '19px', fontWeight: 600 }}>
                        Filter (Optional)
                        <Tooltip title="Use regex pattern for field comparisons.
                                        Operators: && for AND, || for OR.
                                        Example: FieldName1 = Regex1 && FieldName2 = Regex2" placement="right" arrow    
                                        classes={{ tooltip: classes.customTooltip }}>
                        <IconButton size="medium" style={{ marginLeft: 8 }}>
                            <InfoOutlinedIcon style={{ color: TrimbleColors.Blue, fontSize: '30px' }} />
                        </IconButton>
                        </Tooltip>
                    </Typography>
                    <Box display="flex" alignItems="center" mb={2} style={{width: 800}}>
                        <TextField
                            name="filter"
                            onChange={handleFormChange}
                            value={filter}
                            fullWidth
                            rows={4}
                            placeholder="Add your Filter here"
                            variant="outlined"
                            InputProps={{
                                style: {
                                    fontFamily: 'sans-serif',  
                                    fontSize: '18px',         
                                    fontWeight: 300,
                                },
                            }}
                            inputProps={{
                                style: {
                                    fontFamily: 'sans-serif',  
                                    fontSize: '18px',
                                    fontWeight: 300,           
                                },
                            }}
                            style={{
                                backgroundColor: '#fff',
                                borderRadius: 4,
                                width: 800,
                                marginTop: 4,
                                height: '56px'
                            }}
                        />
                        <Button
                            variant="outlined"
                            onClick={handleVerifyClick}
                            style={{
                                marginLeft: 16,
                                marginTop: 3,
                                border: `2px solid ${TrimbleColors.Blue}`,
                                color: TrimbleColors.Blue,
                                backgroundColor: 'transparent',
                                textTransform: 'none',
                                padding: '12px 24px',
                                fontSize: '20px',
                                minWidth: '120px',
                                height: '56px'
                            }}
                        >
                            Verify
                        </Button>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" mt={2} style={{ marginTop: 100, marginRight: 50 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={!isFormValid}
                            style={{
                                backgroundColor: TrimbleColors.Blue,
                                color: '#FFFFFF',
                                textTransform: 'none',
                                padding: '30px 32px',
                                fontSize: '20px',
                                minWidth: '180px',
                                opacity: isFormValid ? 1 : 0.5,
                                cursor: isFormValid ? 'pointer' : 'not-allowed',
                                borderRadius: '6px',
                            }}
                        >
                            Add New Configuration
                        </Button>
                    </Box>
                </form>
            </Box>
        </Drawer>
    );
};

export default EventDrawer;
