import { Box, useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import React from "react";

export const UnsubscribedProductsListLoader:React.FC = () => {
    const theme = useTheme();
    return (
        <Box style={{textOverflow:"ellipsis", maxHeight: "500px"}}>
            {Array.from({length: 5}, (_, index) => (
                <Box key={index} style={{ backgroundColor: '#fff', borderTop: `1px solid ${theme.palette.primary.dark}`, padding: '1rem', position: "relative", minHeight: '105px' }}>
                    <Skeleton width={"40%"} height={"20px"} />
                    <Box mt="10px"/>
                    <Skeleton width={"80%"} height={"10px"}/>
                    <Box mt="3px"/>
                    <Skeleton width={"80%"} height={"10px"}/>
                    <Box mt="3px"/>
                    <Skeleton width={"30%"} height={"10px"}/>
                </Box>
            ))}
        </Box>
    )
}