import { Box, IconButton, InputBase } from "@material-ui/core";
import React, { KeyboardEvent, useRef } from "react";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { ISubscriptionProduct } from "./UnsubscribedProductsList";

interface ProductSubscriptionSearchProps {
  onSearch: (searchText?: string) => void;
}
export const ProductSubscriptionSearch: React.FC<ProductSubscriptionSearchProps> = ({ onSearch }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [selected, setSelected] = React.useState<boolean>(true);
  let previousValue = "";
  const onSearchClicked = () => {
    onSearch(inputRef?.current?.value);
    previousValue = inputRef?.current?.value ?? "";
    setSelected(false);
  };
  const onClearClicked = () => {
    onSearch("");
    if(inputRef.current) {
        inputRef.current.value = "";
    }
    setSelected(true);
  };


  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
          onSearchClicked();
          return;
    }
    if (previousValue !== inputRef.current?.value) {
        setSelected(true);
        }
  }
  return (
    <Box
      style={{
        border: `1px solid #E0E1E9`,
        borderRadius: "2px",
        backgroundColor: "#fff",
        height: "32px",
        fontSize: "12px",
      }}
    >
      <InputBase
        style={{ marginLeft: "15px", flex: 1, height: "32px" }}
        placeholder="Search Products"
        inputProps={{ "aria-label": "Search Products", fontSize: "12px" }}
        inputRef={inputRef}
        onKeyDown={handleKeyDown} 
      />
      {selected && (
        <IconButton
          type="button"
          style={{
            top: "-2px",
            padding: "10px",
            borderRadius: "2px",
            border: `1px solid #0063A3`,
            height: "32px",
            width: "32px",
          }}
          aria-label="search"
          onClick={onSearchClicked}
        >
          <SearchIcon color="primary" />
        </IconButton>
      )}
      {!selected && (
        <IconButton
          type="button"
          style={{
            top: "-2px",
            padding: "10px",
            height: "32px",
            width: "32px",
          }}
          aria-label="search"
          onClick={onClearClicked}
        >
          <CloseIcon color="secondary" />
        </IconButton>
      )}
    </Box>
  );
};


export const filterProductSubscriptionsByText = (ProductSubscriptions: ISubscriptionProduct[], searchText: string) => {
  return ProductSubscriptions?.filter(ProductSubscription => 
    ProductSubscription.name?.toLowerCase().includes(searchText.toLowerCase()) || 
    ProductSubscription?.displayName?.toLowerCase().includes(searchText.toLowerCase()) ||
    ProductSubscription.shortDescription?.toLowerCase().includes(searchText.toLowerCase()) || 
    ProductSubscription.longDescription?.toLowerCase().includes(searchText.toLowerCase())
  );
}