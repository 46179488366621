import { Box, Paper, Button } from "@material-ui/core";
import GenericPageTitle from "modus/components/generic-page-title/GenericPageTitle";
import { IEvent } from "pages/home/subscriptions/pages/subscription-summary-page/SubscriptionSummaryPage";
import React, {useEffect, useState} from "react";
import { EventList } from "../components/EventList";
import { ErrorMessage } from "modus/components/error-message/ErrorMessage";
import EventConfiguration from "../components/EventConfiguration";
import UpdateEventConfiguration from "../components/UpdateEventConfiguration"
import { TrimbleColors } from "legacy-components/TrimbleColors";
import { IService } from "pages/home/subscriptions/pages/subscription-summary-page/SubscriptionSummaryPage";
import EventService from "../service/EventService";
import { useIdentityEnvironmentFilter } from "pages/home/subscriptions/components/identity-environment-filter/IdentityEnvironmentFilter";

interface EventDiscoveryPageProps {
    eventDeployments?: IEvent[];
    subscriptionId: string;
    applicationId: string|undefined;
    serviceConfig: Array<IService> | undefined;
    onChange: () => void;
}

export const EventDiscoveryPage: React.FC<EventDiscoveryPageProps> = ({ eventDeployments, subscriptionId, applicationId, serviceConfig, onChange }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openUpdateEvent, setOpenUpdateEvent] = useState(false);

  const [currentEvent, setCurrentEvent] = useState<any>(undefined);
  const { filterEventEnvironmentList} = useIdentityEnvironmentFilter();
  const serviceConfigDeployments: { [key: string]: string[] } = {};
  const eventConfigDeployments: { [key: string]: string[] } = {};
  const displayConfigDeployments: { [key: string]: string[] } = {};


  useEffect(() => {
    if (!subscriptionId || currentEvent === undefined)
    {
      return;
    }
  }, [subscriptionId, currentEvent])


  serviceConfig?.forEach(service => {

    service.serviceVersions.forEach(serviceVersion => {
      
      if (serviceVersion.eventId !== ''){
        serviceConfigDeployments[serviceVersion.versionName] = [];

        serviceVersion.eventDeployments.forEach(deployment => {
          if(!serviceConfigDeployments[serviceVersion?.versionName].includes(deployment.environment))
            serviceConfigDeployments[serviceVersion?.versionName].push(deployment.environment);
        })
      }
    })
});

      eventDeployments?.forEach(deployment => {

        if (!eventConfigDeployments[deployment.serviceVersion])
          eventConfigDeployments[deployment.serviceVersion] = [];
        if (!eventConfigDeployments[deployment.serviceVersion].includes(deployment.serviceDeployment === 'prd' ? 'production': deployment.serviceDeployment === 'stg' ? 'stage' : deployment.serviceDeployment))
          eventConfigDeployments[deployment.serviceVersion].push(deployment.serviceDeployment === 'prd' ? 'production': deployment.serviceDeployment === 'stg' ? 'stage' : deployment.serviceDeployment);

      })

      for( const key in serviceConfigDeployments) {

        if (eventConfigDeployments[key] !== undefined){
        var difference: string[] = [];
        difference = serviceConfigDeployments[key].filter(env => !eventConfigDeployments[key].includes(env));
        difference = filterEventEnvironmentList(difference);
        
        if (difference.length !== 0)
        displayConfigDeployments[key]=difference;
        }
        else {
          displayConfigDeployments[key] = filterEventEnvironmentList(serviceConfigDeployments[key]);
        }
      }

  const isEmpty = Object.keys(displayConfigDeployments).length === 0;

  const handleDrawerOpen = () => {
      setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
      setDrawerOpen(false);
      setOpenUpdateEvent(false)
  };

  const handleEditClick = (event: any) => {
    setCurrentEvent(event);
    setOpenUpdateEvent(true);
};

  const handleDeleteClick = async(event: any) => {
    setCurrentEvent(event);
    try {
      await EventService.deleteEventSubscription( subscriptionId, event.subscriptionTrn);
      onChange();
  } catch (error) {
      console.error("Error creating subscription:", error);
  }
};

  if(eventDeployments?.length===0 && isEmpty) {
    return <Box/>;
  }
  
  return (
  <Paper style={{padding: "30px", marginTop: "30px"}}>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
    <GenericPageTitle
        noSkeleton
        subTitle={<Box style={{fontSize:'20px'}}>Event Configuration</Box>}
        description={<Box style={{fontSize:'15px'}}>Customize event details to your preferences here by creating new Channels.</Box>}
    />
   
      <Button variant="contained" size="medium" style={{ backgroundColor: isEmpty ? TrimbleColors.Gray9: TrimbleColors.BlueDark, color: '#FFFFFF', marginTop: '10px'}} onClick={handleDrawerOpen}
      disabled={isEmpty}>
                    {isEmpty ? <>No Configurations Available</> : <>Add New Event Configuration</>} 
      </Button>
      </Box>
      <Box display="flex" justifyContent="flex-end" mb={2}>
      <EventConfiguration onChange={onChange} open={drawerOpen} onClose={handleDrawerClose} serviceList={displayConfigDeployments} subscriptionId={subscriptionId} applicationId={applicationId} />
      <UpdateEventConfiguration onChange={onChange} open={openUpdateEvent} onClose={handleDrawerClose} event_details={currentEvent} subscriptionId={subscriptionId} />
    </Box>
    {eventDeployments?.length!==0 && <EventList eventDeployments={eventDeployments} onEditClick={handleEditClick} onChange={onChange} subscriptionId={subscriptionId} />}
    {eventDeployments?.length===0 && <ErrorMessage message={"No Event configuration Initialized yet"}/>}
  </Paper>);
};
