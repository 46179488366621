import { makeStyles, createStyles, Typography, Paper } from "@material-ui/core";
import { useState, RefObject, useRef, ReactElement, useEffect, MutableRefObject, useCallback, PropsWithChildren, FC } from "react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { GridApi, GridReadyEvent, ColDef } from "ag-grid-community";
import { AsyncActionState, useAsyncAction } from "../form/action/AsyncAction";
import { Observable } from "rxjs";
import clsx from "clsx";
import { useGridStyles } from "../grid/GridStyle";
import { StatusBadgeInfo, StatusBadge } from "../badges/StatusBadge";
import { mapToArray } from "../../libs/utils/shared/common";
import Loader from "legacy-components/loader";

export const useGridViewStyles = makeStyles(() =>
    createStyles({
        root: {
            position: "relative",
            width: "100%",
            height: "calc(100% - 24px)"
        },
        content: {
            position: "relative",
            width: "100%",
            height: "calc(100% - 72px)"
        }
    }),
);

const useRowActionStyles = makeStyles(({ spacing }) =>
    createStyles({
        root: {
            display: "flex",
            justifyContent: "flex-end"
        },
        action: {
            marginLeft: spacing(2),
            minWidth: "30px"
        }
    }),
);

export enum GridViewFrameworkComponentsType {
    LoadingOverlay = "LOADING_OVERLAY",
    NoRowsOverlay = "NO_ROWS_OVERLAY",
    RowActions = "ROW_ACTIONS"
}

type GridViewFrameworkComponentState = { [K in GridViewFrameworkComponentsType]: (...params: any) => JSX.Element };

const LoadingComponent = () => <Loader/>;

export interface GridViewOverlayPropRef {
    hasError: boolean;
    noDataMessage: ReactElement;
}

interface RowActionOptions<T = never> {
    actions: (data: T) => ReactElement | ReactElement[],
    width: number
}

const RowActionComponent = ({ data, options }: {
    data: never,
    options: RowActionOptions
}) => {
    const styles = useRowActionStyles();
    const [actions] = useState(mapToArray(options.actions(data)))

    return (
        <div className={styles.root}>
            {
                actions.map((action, index) => {
                    return (
                        <div className={styles.action} key={index}>
                            {action}
                        </div>
                    )
                })
            }
        </div>
    );
};

const NoRowsComponent = (props: {
    gridViewOverlayPropRef: RefObject<GridViewOverlayPropRef>
}) => {
    return (
        <Typography variant="body2">
            {props.gridViewOverlayPropRef?.current?.hasError ? <FormattedMessage defaultMessage="No Results" /> : props.gridViewOverlayPropRef?.current?.noDataMessage ?? <FormattedMessage defaultMessage="No data available" />}
        </Typography>
    );
};

export const useGenericGridViewElements: <T extends Array<any>>(options: {
    resolverFn: () => Observable<T>,
    noDataMessage: ReactElement
}) => {
    frameworkComponents: GridViewFrameworkComponentState,
    onGridReady: (event: GridReadyEvent) => void,
    gridViewOverlayPropRef: MutableRefObject<GridViewOverlayPropRef>,
    getRowNodeId: (data: { id: string }) => string,
    createRowActionColumn: <W>(options: RowActionOptions<W>) => ColDef,
    refreshStream: () => void,
    subscribe: (stream?: boolean) => void,
    collection : AsyncActionState<T | null>
} = ({ resolverFn, noDataMessage }) => {
    const [gridApi, setGridApi] = useState<GridApi | undefined>();

    const [collection, { subscribe }] = useAsyncAction(resolverFn);

    const gridViewOverlayPropRef = useRef<GridViewOverlayPropRef>({
        hasError: false,
        noDataMessage
    });

    const [frameworkComponents] = useState<GridViewFrameworkComponentState>({
        [GridViewFrameworkComponentsType.LoadingOverlay]: LoadingComponent,
        [GridViewFrameworkComponentsType.NoRowsOverlay]: NoRowsComponent,
        [GridViewFrameworkComponentsType.RowActions]: RowActionComponent
    });

    useEffect(() => {

        if (gridApi) {
            gridViewOverlayPropRef.current.hasError = collection.error;

            if (collection.error) {
                gridApi.setRowData([]);
            } else if(collection.loading) {
                gridApi.showLoadingOverlay();
            }else if (collection.loaded && collection.value) {
                gridApi.setRowData(collection.value);
            }
        }

    }, [collection, gridApi]);

    useEffect(() => subscribe(), [subscribe]);

    const onGridReady = useCallback((event: GridReadyEvent) => setGridApi(event.api), []);

    const getRowNodeId = useCallback((data: {
        id: string
    }) => data.id, []);

    const createRowActionColumn: <W>(options: RowActionOptions<W>) => ColDef = useCallback(options => {
        return {
            field: "generic-grid-view-elements-grid-row-actions",
            headerName: "",
            type: 'rightAligned',
            width: options.width,
            cellRenderer: GridViewFrameworkComponentsType.RowActions,
            cellRendererParams: {
                options
            }
        }
    }, []);

    const refreshStream = useCallback(() => subscribe(), [subscribe]);

    return {
        frameworkComponents,
        onGridReady,
        gridViewOverlayPropRef,
        getRowNodeId,
        createRowActionColumn,
        refreshStream,
        subscribe,
        collection
    }
}

interface GridViewAgGridContainerProps {

}

export const GridViewAgGridContainer = ({ children }: PropsWithChildren<GridViewAgGridContainerProps>) => {
    const gridStyle = useGridStyles();

    return (
        <Paper elevation={2} className={clsx("ag-theme-alpine", gridStyle.root, `${gridStyle.header}--tinted`, gridStyle.clickable)}>
            {children}
        </Paper>
    )
}

export const GridViewStatusBadgeCellRenderer: FC<{
    value: StatusBadgeInfo[]
}> = (props) => {
    const [status] = useState(props.value);

    return <StatusBadge status={status} />
}

export const GridViewApprovalStatusBadgeCellRenderer: FC<{
    value: StatusBadgeInfo[]
}> = (props) => {
    const [status] = useState(props.value);

    return <StatusBadge align="right" status={status} />
}

export const EnvironmentStatusBadgeCellRenderer: FC<{
    value: string
}> = ({ value: key }) => {
    const [status] = useState<StatusBadgeInfo[]>([{
        kind: "access",
        key
    }]);

    return <GridViewStatusBadgeCellRenderer value={status} />
}

export const PublishedStatusBadgeCellRenderer: FC<{
    value: string[]
}> = ({ value }) => {
    const [status] = useState<StatusBadgeInfo[]>([{
        kind: "access",
        key: value[0]
    }]);

    if (value[1]) {
        status.push({
            kind: "access",
            key: value[1]
        })
    }
    return <GridViewStatusBadgeCellRenderer value={status} />
}