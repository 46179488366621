import React, { useContext, useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Container,
  makeStyles,
  Box,
} from "@material-ui/core";
import { ApplicationSummaryPageContext } from "./ApplicationSummaryPage";
import { Alert } from "@material-ui/lab";
import TabPanel from "legacy-components/tab-panel/TabPanel";
import ApplicationOverviewTab from "./ApplicationOverviewTab";
import { ResourceVersion } from "../../components/ResourceVersion";
import ResourceServerConfig from "./ResourceServerConfig";
import { SubscriptionsList } from "../../components/SubscriptionsList";
import { SubscriptionCreator } from "../../components/SubscriptionCreator";

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1 },
  tabs: { borderBottom: "none" },
  container: { margin: "0px", padding: "0px" },
  tab: {
    maxWidth: "400px",
    minWidth: "50px",
    padding: "12px 32px 11px 32px",
    height: "47px",
    whiteSpace: "inherit",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tabPanel: {
    margin: "0px",
    padding: "0px",
  },
}));

interface ApplicationSummaryDetailViewProps {}

const ApplicationSummaryDetailView: React.FC<
  ApplicationSummaryDetailViewProps
> = () => {
  const classes = useStyles();
  const { state } = useContext(ApplicationSummaryPageContext);
  const [value, setValue] = useState(0);
 
  const [isResourceApplication, setIsResourceApplication] =
    useState<boolean>(true);
  useEffect(() => {
    if(!setIsResourceApplication || !state?.application?.applicationType?.name) {
      return;
    }
    setIsResourceApplication(
      state?.application?.applicationType?.name !== "application"
    );
  }, [state, state?.application, state?.application?.applicationType?.name, setIsResourceApplication]);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  if (!state) {
    return <Alert severity="warning">No application data available</Alert>;
  }
  const canShowResourceApp =
    process.env.REACT_APP_NEW_RESOURCE_APP_CREATION === "true";

    
  return (
    <Container maxWidth={false} className={classes.container}>
      <>
        {state.application && (
          <div className={classes.root}>
            <Tabs
              value={value}
              onChange={handleChange}
              className={classes.tabs}
              indicatorColor="primary"
            >
              <Tab className={classes.tab} label="OVERVIEW" key="overview" />
              To be enabled once the feature is available
              <Tab
                className={classes.tab}
                label="SUBSCRIPTIONS"
                key="subscriptions"
              />
              {isResourceApplication && canShowResourceApp && (
                <Tab
                  className={classes.tab}
                  label="RESOURCE SERVER CONFIGURATION"
                  key="resource_server_configuration"
                />
              )}
            </Tabs>

            <TabPanel value={value} index={0}>
              <Box className={classes.tabPanel}>
                <ApplicationOverviewTab state={state} />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box className={classes.tabPanel}>
                {state?.application && (
                  <SubscriptionsList application={state?.application} consumerKey={state?.application?.applicationGatewayResponses?.[0]?.consumerKey as string} />
                )}
              </Box>
            </TabPanel>
            {isResourceApplication && canShowResourceApp && (
              <TabPanel value={value} index={2}>
                <Box className={classes.tabPanel}>
                  {state?.application && (
                    <>
                    <ResourceServerConfig state={state} />
                    <ResourceVersion
                      applicationId={state?.application?.applicationGatewayResponses?.[0]?.consumerKey as string}
                      applicationName={state?.application?.applicationName}
                    />
                    </>)
                  }
                </Box>        
              </TabPanel>
            )}
          </div>
        )}
      </>
    </Container>
  );
};

export default ApplicationSummaryDetailView;
