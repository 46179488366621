import {  Box, Grid, Radio, RadioGroup, useTheme } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { ErrorMessage, resolveAPIErrorMessage } from "modus/components/error-message/ErrorMessage";
import { useAsyncAction } from "modus/components/form/async-actions/AsyncAction";
import { Product, ProductDetailsLink } from "pages/home/products/components/ProductStackview";
import React, { useEffect } from "react";
import { UnsubscribedProductsListLoader } from "./UnsubscribedProductsListLoader";
import { ISubscription } from "./SubscriptionsList";
import Badge from "legacy-components/badge/Badge";
import { filterProductSubscriptionsByText, ProductSubscriptionSearch } from "./ProductSubscriptionSearch";
import { Link } from "react-router-dom";
import { ConsumerProductService } from "pages/home/consumer/service/ConsumerProductService";
import { useSelector } from "react-redux";

interface UnsubscribedProductsListProps {
    onChange: (product: Product, event :React.ChangeEvent<HTMLInputElement>    ) => void;
    subscribedProducts: ISubscription[];
    selectedProduct?: Product;
}

const StatusMapping = {
    DENIED: "Denied",
    APPROVED: "Approved",
    SUBSCRIBED: "Subscribed",
    PENDING_REVIEW: "Pending Review",
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      radio: {
        width: '24px', 
        height: '24px',
        '& svg': {
        width: '16px',
        height: '16px' 
      },
      },
    }),
  );

export const UnsubscribedProductsList:React.FC<UnsubscribedProductsListProps> = ({onChange, subscribedProducts, selectedProduct}) => {
    const theme = useTheme();
    const classes = useStyles();
    const identityEnvironment = useSelector((state: any) => state?.common?.identityEnvironment);
    const {state, subscribe, reset} = useAsyncAction(() => ConsumerProductService.getProductList(identityEnvironment));
    const [filteredProducts, setFilteredProducts] = React.useState<ISubscriptionProduct[]>([]);
    const [extendedProducts, setExtendedProducts] = React.useState<ISubscriptionProduct[]>([]);
    useEffect(() => {
        if(subscribe && identityEnvironment) {
            subscribe();
        }
        return () => {
            reset();
        }
    }, [subscribe, reset, identityEnvironment]);

    useEffect(() => {
        if(!state?.value?.data || !subscribedProducts ) {
            return;
        }
        if(subscribedProducts?.length === 0) {
            setExtendedProducts(state?.value?.data);
            return;
        }
        setExtendedProducts(combineSubscriptionData(state?.value?.data, subscribedProducts));
    },[state?.value?.data, subscribedProducts]);


    useEffect(() => {
        setFilteredProducts(extendedProducts);
    },[extendedProducts]);
    
    const filterProducts = (searchText?: string) => {
        if(!searchText || searchText?.trim() === "") {
            setFilteredProducts(extendedProducts);
            return;
        }
        setFilteredProducts(filterProductSubscriptionsByText(extendedProducts, searchText));
    }

    return (
        <>
            {state?.loading && (
                <UnsubscribedProductsListLoader />
            )}
            {state?.loaded && state?.value?.data && filteredProducts && (
                <>
                <Box style={{display:"flex", justifyContent: "space-between", marginBottom:"15px"}}>
                    <Link to="/home/consumer/products/list" style={{textDecoration: "none", color: "#0063A3", alignSelf: "center"}}>
                        View All Products
                    </Link>
                    <ProductSubscriptionSearch onSearch={filterProducts}/>
                </Box>
                <RadioGroup name="product" >
                <Box style={{textOverflow:"ellipsis", maxHeight: "500px",minHeight: "500px", overflowY:"auto",  overflowWrap: "anywhere"}}>
                    {filteredProducts?.map((product: ISubscriptionProduct) => (
                       <Grid container spacing={0} className="product-stack-card" key={product?.id}
                       style={{ backgroundColor: '#fff', borderTop: `1px solid ${theme.palette.primary.dark}`, 
                         padding: '1rem', position: "relative", minHeight: '105px' }}
                     >
                       <Grid item xs={10}>
                        <Box>
                         <ProductDetailsLink id={product?.id} formattedValue={product?.displayName ?? product?.name} />
                         </Box>
                         <p className={"unsubscribed-product-list"}>{product?.shortDescription}</p>
                         
                       </Grid>
                       {product?.isSubscribed && product?.status && (
                            <Box style={{ position: "absolute", right: "10px", top: "22px" }}>
                            <Badge label={StatusMapping[product?.status as keyof typeof StatusMapping]} />
                            </Box>
                       )}
                       {!product?.isSubscribed && (
                       <Box style={{
                            position:"absolute", 
                            height: "calc(100% - 30px)", 
                            display: "flex", 
                            right: "10px", 
                            alignItems: "center"
                            }}>
                        <Radio
                          className={classes.radio}
                          name="product"
                          value={product?.id}
                          radioGroup="product"
                          checked={selectedProduct?.id === product?.id}
                          onChange={(event) => onChange(product, event)}
                          />
                       </Box>)}
                     </Grid>
                    ))}
                    {
                        filteredProducts?.length === 0 && (
                            <ErrorMessage message="No products found" />
                        )
                    }
                </Box>
                </RadioGroup>
                </>
            )}
            {state?.error && (
                <ErrorMessage message={resolveAPIErrorMessage(state.error)} />
            )}
        </>
    );
}

export interface ISubscriptionProduct extends Product {
    isSubscribed?: boolean;
    status?: string;
}
const combineSubscriptionData = (products: Product[], subscribedProducts: ISubscription[]) => {
    return products?.map((product) => {
        return {
            ...product,
            isSubscribed: subscribedProducts?.find((subscribedProduct) => subscribedProduct.productId === product.id) ? 
                    (subscribedProducts?.find((subscribedProduct) => 
                        subscribedProduct.productId === product.id)?.status !== "DENIED"? true: false
                ) : false,
            status: subscribedProducts?.find((subscribedProduct) => subscribedProduct.productId === product.id)?.status ?? null,
        } as ISubscriptionProduct;
    });

}