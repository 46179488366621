import { Button } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Dialog, { useDialogProps } from "legacy-components/dialog/Dialog";
import React, { useEffect } from "react";
import { UnsubscribedProductsList } from "./UnsubscribedProductsList";
import { DataActions } from "modus/components/misc/DataRemoverDialog";
import SubscriptionService from "pages/home/consumer/service/SubscriptionService";
import { IdentityApplication } from "../services/ApplicationService";
import { useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";
import { resolveAPIErrorWithCode } from "modus/components/error-message/ErrorMessage";
import { ErrorMessages } from "pages/home/subscriptions/components/unsubscribed-application-list/ErroCodeMapping";
import { useAsyncAction } from "modus/components/form/async-actions/AsyncAction";
import { ISubscription } from "./SubscriptionsList";
import { Product } from "pages/home/products/components/ProductStackview";

interface SubscriptionCreatorProps {
    application: IdentityApplication;
    refreshSubscriptionList: () => void;
    subscribedProducts: ISubscription[];
}
export const SubscriptionCreator:React.FC<SubscriptionCreatorProps> = ({application, refreshSubscriptionList, subscribedProducts}) => {
    const { open, toggle} = useDialogProps();
    const [selectedProduct, setSelectedProduct] = React.useState<Product|undefined>(undefined);
    const identityEnvironment = useSelector((state: any) => state?.common?.identityEnvironment );
    const {state: subscriptionResponse, subscribe, reset } =
    useAsyncAction(() =>
      SubscriptionService.createSubscription(selectedProduct?.name as string, {
        applicationName: application?.applicationName as string,
        gatewayName: identityEnvironment as string,
        businessContext: null,
      })
    );

    const saveSelectedProduct = (product: Product, event :React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked){
            setSelectedProduct(product);
        }
    }

    useEffect(() => {
        if(subscriptionResponse?.loaded && !subscriptionResponse?.error) {
            toggle();
            reset();
            setSelectedProduct(undefined);
            refreshSubscriptionList();
        }
    },[subscriptionResponse?.loaded]);

    useEffect(() => {
        if(subscriptionResponse?.error) {
            setTimeout(() => {
                reset();
                setSelectedProduct(undefined);
            }, 5000);
        }
    },[subscriptionResponse?.error]);

    const onClose = () => {
        toggle();
        reset();
        setSelectedProduct(undefined);
    }

    return (
        <>
        <Button variant="contained" color="primary" startIcon={<Add/>} onClick={onClose}>Add Subscription</Button>
        <Dialog open={open} onClose={onClose} title={"Select a product you want to subscribe"}>
                {subscriptionResponse?.error && (<Alert 
                        severity={"error"}
                        style={{marginBottom: "1rem"}}
                        
                    >{resolveAPIErrorWithCode(subscriptionResponse?.error, ErrorMessages)}</Alert>)
            }
            <UnsubscribedProductsList selectedProduct={selectedProduct} onChange={saveSelectedProduct} subscribedProducts={subscribedProducts ?? []}/>
            <DataActions 
                onCancelClick={onClose} 
                onSubmit={subscribe}
                submitButtonText={"Subscribe"}
                disabled={!selectedProduct}
                asyncActionState={subscriptionResponse as any}
            />
        </Dialog>
        </>
    )
}