import React, { useCallback } from "react";
import {
  IconButton,
  Tooltip,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  Dialog,
  Box,
  Typography,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";

import AlertService from "legacy-components/alert/AlertService";
import { DialogTitle, useDialogProps } from "legacy-components/dialog/Dialog";
import { useHistory } from "react-router";
import EventService from "../service/EventService";
import { TrimbleColors } from 'legacy-components/TrimbleColors';

export interface DeleteEventConfProps {
  subscriptionId: string;
  subscriptionTrn: string;
  onChange: ()=> void;
}

export const DeleteEventConf: React.FC<DeleteEventConfProps> = ({
  subscriptionId,
  subscriptionTrn,
  onChange
}) => {
  const { open, toggle } = useDialogProps();
  const history = useHistory();

  const deleteEventSubscriptionConf = useCallback(() => {
    EventService.deleteEventSubscription( subscriptionId, subscriptionTrn)
      .toPromise()
      .then(
        (output) => {
          AlertService.success({
            message: `Event subscription configuration is deleted`,
            isAutoHide: true,
            id: "UNSUBSCRIBER",
          });
          toggle();
          setTimeout(() => {
            onChange();
          }, 1000);
        },
        (error) => {
          AlertService.error({
            message: "Failed to Delete Event subscription configuration",
            isAutoHide: true,
            id: "UNSUBSCRIBER",
          });
          toggle();
        }
      );
  }, [subscriptionId, toggle, subscriptionTrn, onChange]);

  return (
    <>
      <Tooltip title={"Delete"}>
        <span>
          <IconButton onClick={toggle}  data-testid={`${subscriptionId}-delete-btn`}>
            <DeleteIcon style={{ color: TrimbleColors.Blue }}/>
          </IconButton>
        </span>
      </Tooltip>
        <Dialog maxWidth={"md"} open={open} title="Delete Event Subscription Configuration" onClose={toggle} style={{padding: "20px"}}>

        <DialogTitle title={"Remove Subscription"} handleClose={toggle}/>
          <Box padding="0 20px" ><Divider/></Box>
          <DialogContent>
          <Box padding="30px" >
            <Typography variant="body1" align="center" style={{fontSize: "18px", lineHeight:"44px"}}>
              Are you sure you want to delete this event subscription configuration?
            </Typography>
            </Box>
          </DialogContent>
          <DialogActions  style={{padding:"20px"}}>
            <Button variant="outlined" color="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button onClick={deleteEventSubscriptionConf} color="primary" variant="contained"> Delete </Button>
          </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteEventConf;