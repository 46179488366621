import { createClient } from "libs/utils/http/client/HttpClient";



export const ConsumerClient = createClient("ConsumerClient", {
    baseURL: `${process.env.REACT_APP_CONSUMER_API_URL}/unifiedportal/publisher`
});

export const ConsumerClient2 = createClient("ConsumerClient2", {
    baseURL: `${process.env.REACT_APP_CONSUMER_API_URL}/unifiedportal/publisher/v2`
});


export default ConsumerClient;