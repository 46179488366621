import { Box, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ApplicationIcon from "legacy-components/icons/ApplicationIcon";
import React from "react";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";

export const ApplicationNavigation: React.FC = () => {
  const { push } = useHistory();
  return (
    <NavLink
      className="navigation-tab"
      key={"applications"}
      activeClassName="active"
      to={"/home/applications"}
    >
      <ListItem button onClick={() => push("/home/applications")}>
        <ListItemIcon>
          <ApplicationIcon style={{ color: "#fff" }} />
        </ListItemIcon>
        <ListItemText
          className="sub-menu-text"
          primary={
            <Box display="flex">
              <Box flexGrow={1}>Applications</Box>
            </Box>
          }
        />
      </ListItem>
    </NavLink>
  );
};
