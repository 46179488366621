import { Box, Collapse, Grid, Link, Tooltip, Typography, useTheme } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import React from "react";
import clsx from "clsx";
import { useHistory } from "react-router";
import ExpandCircleDown from "legacy-components/icons/ExpandCircleDown";

export interface Product {
    id: string;
    name: string;
    displayName?: string;
    shortDescription?: string;
    longDescription?: string;
    created?: string;
    updated?: string;
    visibility?: string;
}
export const ProductStackCard: React.FC<Product> = ({
    id,
    displayName,
    name,
    shortDescription,
    longDescription
  }) => {
    const theme = useTheme();
    const [expanded, setExpanded] = React.useState(false);  
    return (
      <Grid container className="product-stack-card" key={id}
        style={{ backgroundColor: '#fff', borderTop: `1px solid ${theme.palette.primary.dark}`,
          
          padding: '1rem', position: "relative", minHeight: '105px' }}
      >
        <Grid item>
          <ProductDetailsLink id={id} formattedValue={displayName ?? name} />
          <p>{shortDescription}</p>
          {longDescription && (
            <>
            <Box style={{position:"absolute", bottom: "10px", right: "10px"}}>
              <ExpandCircleDown 
                onClick={() => setExpanded(!expanded)} 
                className={clsx(["product-collapse", { "product-expanded": expanded}])}/>
            </Box>
             <Collapse in={expanded} timeout="auto" unmountOnExit>
                {longDescription}  
              </Collapse>
            </>
          )}
          {
            !longDescription && (

              <Tooltip title="No detailled description available" placement="left">
              <Box style={{position:"absolute", bottom: "10px", right: "10px"}}>
                <ExpandCircleDown 
                  onClick={() => setExpanded(!expanded)} 
                  className={clsx(["product-collapse", { "product-disabled": true}])}/>
              </Box>

              </Tooltip>
            )
          }
        </Grid>
      </Grid>
    );
  };


export const Actions = (params: any) => {
    return <Delete />;
  };
  
  export const ProductDetailsLink = (params: any) => {
    const history = useHistory();
    const onClick = () => {
       history.push(`/home/consumer/products/${params.id}`);
    }
    return <Typography variant="body1">
      <Link className="link" onClick={onClick} {...params} 
        style={{fontSize:"18px",
          fontWeight: 400,
          lineHeight: "36px",
          color: "#004F83"
        }}>{params.formattedValue}</Link></Typography>;
  };